<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-row flex-grow-1 justify-space-between">
          <h3
            class="font-weight-bold blue-grey--text mt-2 font font-md font-weight-medium text-uppercase"
          >
            All Balances
          </h3>
        </div>
      </v-col>
      <v-col cols="12" sm="12">
        <div class="d-flex flex-row flex-grow-1 justify-end">
          <v-text-field
            solo
            rounded
            placeholder="Search through transactions using account number ..."
            flat
            append-icon="search"
            v-model="search"
            hide-details
            class="box-shadow-light font font-sm"
            style="min-width: 400px"
          >
          </v-text-field>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="pageLoading"
                class="mx-2 mt-1"
                @click="$store.dispatch('balances/list', '?page=1&size=30')"
                v-on="on"
                small
                fab
                icon
              >
                <i class="material-icons-outlined">refresh</i>
              </v-btn>
            </template>
            <span class=" font font-sm">Get latest transactions</span>
          </v-tooltip>
          <v-btn
            color="primary"
            @click="open({ idx: 'add', status: true })"
            class=" mt-2"
          >
            <span class=" font font-weight-medium  font-sm"
              >Upload Balances</span
            >
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="12" lg="12" md="12" xl="12">
        <v-data-table
          :headers="headers"
          :items="balances"
          :loading="pageLoading"
          item-key="branch"
          fixed-header
          height="100%"
          :server-items-length="paginate.total"
          :options.sync="options"
          class="font text-capitalize box-shadow-light mx-1 mt-n2"
          loading-text="Loading... Please wait"
          :footer-props="{
            itemsPerPageOptions: [30, 40, 50]
          }"
        >
          <template #item.customerId="{item}">
            <v-chip label small>
              <span v-if="item.customerId" class=" font-sm font-weight-medium"
                >{{
                  item.customerId.firstName ? item.customerId.firstName : ""
                }}
                {{
                  item.customerId.lastName ? item.customerId.lastName : ""
                }}</span
              >
            </v-chip>
          </template>
          <template #item.shares="{item}">
            <span class=" font-sm font-weight-medium"
              >{{ item.shares | currencyFormat("GHC") }}
            </span>
          </template>

          <template #item.susu="{item}">
            <span class=" font-sm font-weight-medium"
              >{{ item.susu | currencyFormat("GHC") }}
            </span>
          </template>

          <template #item.kidsKitty="{item}">
            <span class=" font-sm font-weight-medium"
              >{{ item.kidsKitty | currencyFormat("GHC") }}
            </span>
          </template>
          <template #item.specialDeposit="{item}">
            <span class=" font-sm font-weight-medium"
              >{{ item.specialDeposit | currencyFormat("GHC") }}
            </span>
          </template>
          <template #item.loans="{item}">
            <span class=" font-sm font-weight-medium"
              >{{ item.loans | currencyFormat("GHC") }}
            </span>
          </template>
          <template #item.savings="{item}">
            <span class=" font-sm font-weight-medium"
              >{{ item.savings | currencyFormat("GHC") }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <BulkUploadDialog
      :state="dialog"
      @actions="open"
      @uploadFile="uploadFile"
      :loading="isLoading"
    />
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import SnackBarMIxins from "@/mixins/SnackBarMIxins";
import { loadComponent } from "@/utils/helpers";
export default {
  name: "Transactions",
  components: {
    BulkUploadDialog: () => import(`../../../component/BulkUploadDialog`),
    SnackBar: loadComponent("component/SnackBar")
  },
  data() {
    return {
      search: "",
      options: {
        page: 1,
        itemsPerPage: 30
      },
      menu: false,
      dates: [],
      chosenDateRange: []
    };
  },
  mixins: [SnackBarMIxins],
  filters: {
    currencyFormat(value) {
      return new Intl.NumberFormat("en-GB", {
        currency: "GHS",
        style: "currency",
        minimumFractionDigits: 2
      }).format(value);
    }
  },

  computed: {
    ...mapGetters({
      pageLoading: "getIsPageLoading",
      paginate: "balances/getPagination",
      balances: "balances/getAllBalances",
      isLoading: "getIsLoading"
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    transactionsList() {
      return this.transactions.filter(searchTerm => {
        return (
          searchTerm.sequenceID
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1
        );
      });
    },
    headers() {
      return [
        {
          text: "",
          value: "",
          filterable: false,
          sortable: false
        },
        {
          text: "Customer Name",
          value: "customerId",
          filterable: true,
          sortable: false
        },
        {
          text: "Acc. #",
          value: "accountNumber",
          filterable: true,
          sortable: false
        },
        { text: "Susu", value: "susu" },
        { text: "Shares Capital", value: "shares" },
        { text: "Member Savings", value: "savings" },
        { text: "Kids & Kitty", value: "kidsKitty" },
        { text: "Special Deposit", value: "specialDeposit" },
        { text: "Loan (Repayment)", value: "loans" }
      ];
    },
    dialog() {
      return this.$store.getters["getDialogState"]("add");
    }
  },
  methods: {
    open(payload) {
      this.$store.dispatch("updateDialog", payload, { root: true });
    },
    uploadFile(file) {
      this.$store.dispatch("balances/uploadBalances", file);
    }
  },
  watch: {
    search: debounce(function(searchTerm) {
      if (searchTerm !== "") {
        const query = `?searchTerm=${searchTerm}&page=1&size=30`;
        this.$store.dispatch("balances/searchList", query);
      } else {
        this.$store.dispatch("balances/list", "?page=1&size=30");
      }
    }, 800),
    options: {
      handler(value) {
        const { page, itemsPerPage } = value;
        const query = `?page=${page}&size=${itemsPerPage}`;
        this.$store.dispatch("balances/list", query);
      },
      deep: true
    }
  },
  created() {
    const query = `?page=1&size=30`;
    this.$store.dispatch("balances/list", query);
  }
};
</script>

<style scoped>
.createNew {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  alignt-content: stretch;
}

.box {
  height: auto;
  width: auto;
  padding: 5%;
}
</style>
